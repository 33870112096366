<template>
  <router-view />
</template>

<script>
import { watch } from "vue";
import { useI18n } from "vue-i18n";

// window.onresize = function () {
//   let vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty("--vh", `${vh}px`);
// };

if(localStorage.getItem("locale")== null)  {
  let userLang = navigator.language || navigator.userLanguage; 
  if(userLang == "zh-TW") {
    localStorage.setItem("locale", "zh-TW");
  } 
}


window.addEventListener('resize', function () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

window.addEventListener('orientationchange', function () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

document.addEventListener('touchmove', function (event) {
  var xStart = event.touches[0].clientX;
  var yStart = event.touches[0].clientY;

  document.addEventListener('touchmove', function (event) {
    var xMovement = Math.abs(event.touches[0].clientX - xStart);
    var yMovement = Math.abs(event.touches[0].clientY - yStart);

    if (xMovement > yMovement) {
      event.preventDefault();
    }
  });
});

export default {
  name: "app",
  setup() {
    const { t, locale } = useI18n();

    watch(locale, (newlocale) => {
      localStorage.setItem("locale", newlocale);
    });

    return {
      t,
      locale,
    };
  },
  mounted() {
    console.warn("CICD ver. 2023/09/12 18:30");

    if (localStorage.getItem("themeColor") != null) {
      this.changeColor(localStorage.getItem("themeColor"));
    }

    // this.identifyDevice();
  },
  methods: {
    identifyDevice() {
      let useragent = navigator.userAgent;
      let regex =
        "/(WebView|(iPhone|iPod|iPad)(?!.*Safari/)|Android.*(wv|.0.0.0))/gi";
      if (useragent.match(regex)) {
        alert(this.t("open_by_safari_or_chrome_browser"));
      }
    },
    changeColor(colorIndex) {
      document.documentElement.style.setProperty('--font-color', this.$themeList[colorIndex].fontColor);
      document.documentElement.style.setProperty('--primary-color', this.$themeList[colorIndex].primaryColor);
      document.documentElement.style.setProperty('--primary-color-reverse', this.$themeList[colorIndex].primaryColorReverse);
      document.documentElement.style.setProperty('--secondary-color', this.$themeList[colorIndex].secondaryColor);
      document.documentElement.style.setProperty('--info-color', this.$themeList[colorIndex].infoColor);
      document.documentElement.style.setProperty('--light-color', this.$themeList[colorIndex].lightColor);
      document.documentElement.style.setProperty('--dark-color', this.$themeList[colorIndex].darkColor);
      document.documentElement.style.setProperty('--app-background', this.$themeList[colorIndex].appBackground);
      document.documentElement.style.setProperty('--nav-background', this.$themeList[colorIndex].navBackground);
      document.documentElement.style.setProperty('--card-background', this.$themeList[colorIndex].cardBackground);
    },
  },
};
</script>

<style>
:root {
  --font-color: #000000;
  --primary-color: #44D4DC;
  --primary-color-reverse: #FFFFFF;
  --secondary-color: #EAEAEA;
  --info-color: #979797;
  --light-color: rgba(68, 212, 220, 0.1);
  --dark-color: rgba(68, 212, 220, 0.6);
  --card-background: linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%);
  --nav-background: linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%);
  --app-background: linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%);
}

@font-face {
  font-family: Myanmar;
  src: url("./assets/myanmar.ttf");
}

body {
  margin: 0;
  background: var(--primary-color-reverse);
  overflow-x: hidden;
}

#app {
  font-family: Myanmar, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 414px;
  margin: 0 auto;
  height: calc(var(--vh, 1vh) * 100);
  /* border: 1px solid #979797; */
  overflow: hidden;
  background: var(--app-background);
  position: relative;
}

#app>.flex-container {
  height: calc(100% + -60px);
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

#app>div:not(.navigation):not(.flex-container):not(.begin) {
  height: calc(100% + -60px);
  overflow-y: scroll;
  overflow-x: hidden;
}

#app>div:not(.navigation):not(.flex-container):not(.begin)>.wrapper {
  min-height: calc(100% + -60px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#app>div:not(.navigation):not(.flex-container):not(.begin)>.wrapper>* {
  flex-shrink: 0;
}

#app>div:not(.navigation) {
  padding: 0 10%;
}

#app>.login,
#app>.otp,
#app>.refereer {
  background: white;
}

button {
  font-family: Myanmar, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  height: 56px;
  background: rgba(255, 255, 255, 0);
  border: 1px solid var(--primary-color);
  border-radius: 30px;
  color: var(--primary-color);
  margin: 10px 0px;
}

input {
  border-radius: 0px;
  font-family: Myanmar;
  font-size: 18px;
  color: var(--primary-color);
  background: var(--primary-color-reverse);
}

input::placeholder {
  font-size: 18px;
  color: var(--info-color);
}

input:focus-visible {
  outline: none;
}

input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0) !important;
  color: var(--primary-color) !important;
}

::-webkit-scrollbar {
  /* display: none; */
  /* background: black; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border-radius: 15px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-corner {
  opacity: 0;
}

.hint {
  font-size: 14px;
  text-align: center;
  color: var(--info-color);
}

h1,
h2,
h3 {
  color: var(--font-color);
}

h1 {
  font-size: 24px;
}

h5 {
  color: var(--primary-color);
}

h3 {
  color: var(--primary-color);
}

h4 {
  color: var(--primary-color);
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}

h6 {
  color: var(--info-color);
}

p {
  color: var(--primary-color);
}

span {
  color: var(--font-color);
}

label {
  color: var(--primary-color-reverse);
}</style>
