import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/index";
import zh from "../language/zh-TW.json";
import en from "../language/en-US.json";
import VueGtag from 'vue-gtag';

const app = createApp(App);
 
app.config.globalProperties.$env = {
  database: "https://xti6xzxxb3.execute-api.ap-southeast-1.amazonaws.com/prod",
  // DEV: "https://iwl9x67rq1.execute-api.ap-northeast-1.amazonaws.com/prod",
  // UAT: "https://dekjo372wk.execute-api.ap-southeast-1.amazonaws.com/prod",
  // PROD: "https://xti6xzxxb3.execute-api.ap-southeast-1.amazonaws.com/prod",

  host: "www.i-hap.com",
  // DEV: "54.64.196.45",
  // UAT: "54.179.28.96",
  // PROD: "www.i-hap.com",
};

app.config.globalProperties.$themeList = [
  {
    name: "clean",
    fontColor: "#000000",
    primaryColor: "#000000",
    primaryColorReverse: "#FFFFFF",
    secondaryColor: "#EAEAEA",
    infoColor: "#979797",
    lightColor: "rgba(0, 0, 0, 0.1)",
    darkColor: "rgba(0, 0, 0, 0.6)",
    appBackground: "linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%)",
    navBackground: "linear-gradient(135deg, #ffffff 0%, #eeeeee 100%)",
    cardBackground: "#FFFFFF",
    setupColor: "#000000",
    setupBackground: "#FFFFFF",
  },
  {
    name: "midnight",
    fontColor: "#FFFFFF",
    primaryColor: "#FFFFFF",
    primaryColorReverse: "#000000",
    secondaryColor: "#999999",
    infoColor: "#BBBBBB",
    lightColor: "rgba(100, 100, 100, 0.1)",
    darkColor: "rgba(100, 100, 100, 0.6)",
    appBackground: "linear-gradient(135deg, #000000 0%, #000000 100%)",
    navBackground: "linear-gradient(135deg, #000000 0%, #000000 100%)",
    cardBackground: "#000000",
    setupColor: "#FFFFFF",
    setupBackground: "#000000",
  },
  {
    name: "purple",
    fontColor: "#000000",
    primaryColor: "#C9A1FF", 
    primaryColorReverse: "#FFFFFF",
    secondaryColor: "#EAEAEA",
    infoColor: "#979797",
    lightColor: "rgba(225, 203, 255, 0.1)",
    darkColor: "rgba(212, 183, 253, 0.6)",
    appBackground: "linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%)",
    navBackground: "linear-gradient(135deg, #ffffff 0%, #eeeeee 100%)",
    cardBackground: "#FFFFFF",
    setupColor: "#FFFFFF",
    setupBackground: "#E1CBFF",
  },
  {
    name: "baby",
    fontColor: "#000000",
    primaryColor: "#44D4DC",
    primaryColorReverse: "#FFFFFF",
    secondaryColor: "#EAEAEA",
    infoColor: "#979797",
    lightColor: "rgba(68, 212, 220, 0.1)",
    darkColor: "rgba(68, 212, 220, 0.6)",
    appBackground: "linear-gradient(135deg, #ffffff 0%, #e4e6eb 100%)",
    navBackground: "linear-gradient(135deg, #ffffff 0%, #eeeeee 100%)",
    cardBackground: "#FFFFFF",
    setupColor: "#FFFFFF",
    setupBackground: "#BBEFFF",
  },
];

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") ?? "en-US",
  fallbackLocale: "en-US",
  messages: {
    "zh-TW": zh,
    "en-US": en,
  },
});

app.use(VueGtag, {
  config: { id: 'G-ZQCN0LSKZG' },
});

app.use(router).use(i18n).mount("#app");


