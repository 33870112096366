import { createRouter, createWebHashHistory } from "vue-router";
// import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/addARelationship",
    name: "addARelationship",
    component: () => import("@/views/AddARelationship.vue"),
  },
  {
    // 路徑為根目錄，使用 begin 元件
    path: "/",
    name: "begin",
    component: () => import("@/views/Begin.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/editProfile",
    name: "editProfile",
    component: () => import("@/views/EditProfile.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import("@/views/Invitation.vue"),
  },
  {
    // 路徑為 /login，進入網址時才載入 login 元件
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/myFollow",
    name: "myFollow",
    component: () => import("@/views/MyFollow.vue"),
  },
  {
    path: "/myNotifications",
    name: "myNotifications",
    component: () => import("@/views/MyNotifications.vue"),
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("@/views/OTP.vue"),
    props: (route) => route.params,
  },
  {
    path: "/paperPlane",
    name: "paperPlane",
    component: () => import("@/views/PaperPlane.vue"),
  },
  {
    path: "/paperPlaneRead",
    name: "paperPlaneRead",
    component: () => import("@/views/PaperPlaneRead.vue"),
    props: (route) => route.params,
  },
  {
    path: "/paperPlaneReply",
    name: "paperPlaneReply",
    component: () => import("@/views/PaperPlaneReply.vue"),
    props: (route) => route.params,
  },
  {
    path: "/refer",
    name: "refer",
    component: () => import("@/views/Refer.vue"),
  }, 
  {
    path: "/refereer",
    name: "refereer",
    component: () => import("@/views/Refereer.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/Search.vue"),
  },
  {
    path: "/searchResult",
    name: "searchResult",
    component: () => import("@/views/SearchResult.vue"),
    props: (route) => route.params,
  },
  {
    path: "/searchNotFound",
    name: "searchNotFound",
    component: () => import("@/views/SearchNotFound.vue"),
  },
  {
    path: "/setupPage",
    name: "setupPage",
    component: () => import("@/views/SetupPage.vue"),
  },
  {
    path: "/socialMedia",
    name: "socialMedia",
    component: () => import("@/views/SocialMedia.vue"),
    props: (route) => route.params,
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
  // fallback: true,
});

router.beforeEach((to) => {
  let isDefinedURL = false;

  routes.forEach((element) => {
    if (to.name == element.name) {
      isDefinedURL = true;
    }
  });

  if (to.fullPath.includes("invitation?invitationCode=")) {
    isDefinedURL = true;
  }

  if (!isDefinedURL) {
    if (localStorage.getItem("userToken") == null) {
      return { name: "login" };
    } else {
      return { name: "home" };
    }
  }
  if (
    to.name !== "begin" &&
    to.name !== "login" &&
    to.name !== "otp" &&
    !to.fullPath.includes("invitation?invitationCode=")
  ) {
    if (localStorage.getItem("userToken") == null) {
      return { name: "login" };
    }
  }
});

export default router;
